import { Skeleton } from "@material-ui/lab";
import React from "react";

const Loader = () => (
  <>
    <Skeleton variant="text" width="25rem" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </>
);

export default Loader;
