import React, { useCallback, useEffect, useState } from "react";
import EntityDialog from "../../components/EntityDialog";
import { EntityType } from "../../store/@core/entityType";
import { useForm } from "reactcoregk/hooks";
import { Schema } from "../../store/@core/schema";
import AttackForm from "./AttackForm";
import ManageActorsDialog from "./AttackDetails/ManageActorsDialog";
import ManageCvesDialog from "./AttackDetails/ManageCvesDialog";
import Alert from "@material-ui/lab/Alert";
import { Collapse } from "@material-ui/core";
import { handleAttackPreSubmit } from "./logic";
import { postData } from "helpers/utils";
import { API_URL } from "config";
import { useSnackbar } from "notistack";

function EditDialog(props) {
  const {
    entity,
    open,
    techs,
    openActors,
    setOpenActors,
    openCves,
    setOpenCves,
    handleClose,
  } = props;
  const [form, handleChange, setForm] = useForm(Schema[EntityType.Attack]);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState("");
  const [attackFormCharConstraint, setAttackFormCharConstraint] =
    useState(true);
  useEffect(() => {
    if (entity) setForm(entity);
  }, [entity, setForm, open]);

  const [selectedFiles, setSelectedFiles] = useState({
    attackPath: null,
    sigmaRules: null,
  });

  const onActorsSelect = useCallback(
    (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        actorIds: selected,
      }));
    },
    [setForm]
  );

  const onCvesSelect = useCallback(
    (selected) => {
      setForm((prevForm) => ({
        ...prevForm,
        cves: selected,
      }));
    },
    [setForm]
  );

  const createAttack = useCallback(
    async (payload) => {
      try {
        const attackData = await postData(API_URL + "/api/attacks", payload);
        if (attackData && attackData.id) {
          enqueueSnackbar("Attack created Successfully", {
            variant: "success",
          });
          if (selectedFiles.sigmaRules !== null) {
            const url = `${API_URL}/api/attacks/${attackData?.id}/upload/sigma-rules`;
            const formData = new FormData();
            formData.append("file", selectedFiles.sigmaRules);
            postData(url, formData);
          }

          if (selectedFiles.attackPath !== null) {
            const url = `${API_URL}/api/attacks/${attackData?.id}/upload/attack-path`;
            const formData = new FormData();
            formData.append("file", selectedFiles.attackPath);
            postData(url, formData);
          }
          handleClose();
        }
      } catch (e) {
        enqueueSnackbar("Operation Failed!", { variant: "error" });
      }
    },
    [selectedFiles]
  );

  const createEntity = (form) => {
    handleAttackPreSubmit(form, setError, createAttack);
  };

  return (
    <EntityDialog
      {...props}
      createEntity={createEntity}
      form={form}
      maxWidth={"md"}
      isValid={attackFormCharConstraint}
    >
      <Collapse in={!!error}>
        <Alert
          severity="error"
          onClose={() => setError("")}
          style={{ marginBottom: 8 }}
        >
          {error}
        </Alert>
      </Collapse>
      <AttackForm
        form={form}
        setForm={setForm}
        isFullWidth={false}
        handleChange={handleChange}
        techs={techs}
        openActors={() => setOpenActors(true)}
        openCves={() => setOpenCves(true)}
        setAttackFormCharConstraint={setAttackFormCharConstraint}
        setSelectedFiles={setSelectedFiles}
      />
      <ManageActorsDialog
        open={openActors}
        attack={form}
        callback={onActorsSelect}
        handleClose={() => setOpenActors(false)}
      />
      <ManageCvesDialog
        open={openCves}
        attack={form}
        handleClose={() => setOpenCves(false)}
        callback={onCvesSelect}
      />
    </EntityDialog>
  );
}

export default EditDialog;
