import React, { useState } from "react";
import { Grid, IconButton, Slide } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BusyIndicator from "../../components/Common/BusyIndicator";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CardContent from "@material-ui/core/CardContent";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 8,
    marginLeft: 8,
    marginTop: 8,
    position: "relative",
  },
  title: {
    fontSize: 18,
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    padding: 8,
  },
  actionContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 120,
  },
}));

export default function FileCard(props) {
  const classes = useStyles();
  const {
    busy,
    file,
    handleDownload,
    handleDelete,
    handlePreview,
    canDelete = true,
    canDownload = true,
  } = props;
  const [focused, setFocused] = useState(false);

  return (
    <Card variant={"outlined"} className={classes.root}>
      <BusyIndicator busy={busy} />
      <CardContent
        className={classes.actionContent}
        onMouseEnter={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
      >
        <AttachFileIcon />
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.title}
        >
          {file}
        </Typography>
        <Slide direction="up" in={focused} mountOnEnter unmountOnExit>
          <Grid container spacing={1} alignItems={"center"} justify={"center"}>
            <Grid item>
              <IconButton
                onClick={handleDownload}
                disabled={busy || !canDownload}
              >
                <CloudDownloadIcon />
              </IconButton>
            </Grid>
            {Boolean(handlePreview) && (
              <Grid item>
                <IconButton onClick={handlePreview} disabled={busy}>
                  <VisibilityIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <IconButton onClick={handleDelete} disabled={busy || !canDelete}>
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Slide>
      </CardContent>
    </Card>
  );
}
