import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SearchSelect from "../../components/Inputs/SearchSelect";
import {
  AFFECTED_PRODUCTS,
  ATTACK_EXECUTORS,
  ATTACK_FORMAT_TYPES,
  ATTACK_PHASE_OPTIONS,
  ATTACK_PREVENTION_CONTROLS_OPTIONS,
  ATTACK_SOURCE_TYPES,
  ATTACK_SUPPORTED_SYSTEMS,
  AttackTypes,
  CREDENTIAL_TYPES,
  TARGET_TYPES,
  VERSIONS,
} from "../../constants/enums";
import { createMap, createOptions } from "reactcoregk/utils";
import MultiSelect from "../../components/Inputs/MultiSelect";
import ButtonChoseList from "../../components/Common/ButtonChoseList";
import FreeSolo from "../../components/Inputs/FreeSolo";
import SignatureDialog from "./AttackDetails/SignatureDialog";
import Select from "../../components/Inputs/Select";
import {
  AddCircleOutline,
  FolderOpen,
  RemoveCircleOutline,
} from "@material-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import _, { cloneDeep } from "lodash";
import { fetchData, downloadFile, deleteData, postData } from "helpers/utils";
import { API_URL } from "config";
import Loader from "components/Common/Loader";
import SectionGroupDescription from "components/SectionGroupDescription";
import { HiveIcons } from "assets/icons";
import FileCard from "components/Common/FileCard";
import { useDropzone } from "reactcoregk/hooks";
import BaseDialog from "components/Common/BaseDialog";
import HiDrop from "components/HiDrop";
import FilePreview from "./AttackDetails/FilePreview";

const ATTACK_SIGMA_RULES = "ATTACK_SIGMA_RULES";
const ATTACK_PATH = "ATTACK_PATH";

function AttackForm({
  form,
  handleChange,
  setForm,
  techs,
  busy,
  openActors,
  openCves,
  setAttackFormCharConstraint,
  isFullWidth = false,
  setSelectedFiles,
  getAttack,
}) {
  const [openSignatures, setOpenSignatures] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const subTechniques = techs["subTechniques"] || [];
  const techniques = techs["techniques"] || [];
  const tactics = techs["tactics"] || [];
  const techMap = createMap(techniques);
  const subTechniquesMap = createMap(subTechniques);

  const [isOpenFileUpload, setOpenFileUpload] = useState({
    from: "",
    status: false,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const {
    handleAcceptedFiles: handleAcceptSigmaRules,
    setSelectedFiles: setSelectedSigmaRuleFile,
    selectedFiles: selectedSigmaRuleFile,
  } = useDropzone();

  const {
    handleAcceptedFiles: handleAcceptAttackPathFile,
    setSelectedFiles: setSelectedAttackPathFile,
    selectedFiles: selectedAttackPathFile,
  } = useDropzone();

  const onCloseUploadDialog = () => {
    setSelectedSigmaRuleFile([]);
    setSelectedAttackPathFile([]);
    setOpenFileUpload({ from: "", status: false });
  };

  const handleUpload = async (id) => {
    if (form.id) {
      const formData = new FormData();
      let url = `${API_URL}/api/attacks/${form?.id}/upload/sigma-rules`;
      if (selectedSigmaRuleFile && selectedSigmaRuleFile.length > 0) {
        formData.append("file", selectedSigmaRuleFile[0]);
      } else if (selectedAttackPathFile && selectedAttackPathFile.length > 0) {
        url = `${API_URL}/api/attacks/${form?.id}/upload/attack-path`;
        formData.append("file", selectedAttackPathFile[0]);
      }
      onCloseUploadDialog();
      await postData(url, formData);
      getAttack(form.id);
    } else {
      setOpenFileUpload({ from: "", status: false });
    }
  };

  useEffect(() => {
    let files = {
      sigmaRules:
        selectedSigmaRuleFile && selectedSigmaRuleFile.length > 0
          ? selectedSigmaRuleFile[0]
          : null,
      attackPath:
        selectedAttackPathFile && selectedAttackPathFile.length > 0
          ? selectedAttackPathFile[0]
          : null,
    };
    setSelectedFiles(files);
  }, [selectedAttackPathFile, selectedSigmaRuleFile]);

  useEffect(() => {
    setLoading(true);
    fetchData(API_URL + "/api/bas/categories")
      .then((res) => {
        setCategories(_.filter(res, (x) => x.entityType === "BAS_ATTACK"));
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filteredTechniques = techniques.filter((x) => {
    const tactIds = form.tacticIds || [];
    return tactIds[0] ? tactIds.includes(x.tacticId) : true;
  });

  const filteredSubTechniques = subTechniques.filter((x) => {
    const techIds = form.techniqueIds || [];
    return techIds[0] ? techIds.includes(x.techniqueParentId) : !form.tacticId;
  });

  const tacticOptions = createOptions(tactics, "name");
  const techniqueOptions = createOptions(filteredTechniques, "name");
  const subTechniqueOptions = createOptions(filteredSubTechniques, "name");

  const categoryOptions = createOptions(categories, "name");

  const handleChangeAdversaryTools = (e, newval) => {
    setForm((prevForm) => ({
      ...prevForm,
      adversaryTools: newval,
    }));
  };

  const handleChangeAffectedProducts = (e, affectedPrograms) => {
    setForm((prevForm) => ({
      ...prevForm,
      affectedPrograms,
    }));
  };

  const handleReferences = (e, references) => {
    setForm((prevForm) => ({
      ...prevForm,
      references,
    }));
  };

  const handleChangeTactic = (tacticIds) => {
    const techIds = form.techniqueIds || [];
    const subTechIds = form.subTechniqueIds || [];

    const techniqueIds = techIds.filter((id) => {
      const technique = techMap.get(id);
      return tacticIds.includes(technique?.tacticId);
    });

    const subTechniqueIds = subTechIds.filter((id) => {
      const technique = subTechniquesMap.get(id);
      return techniqueIds.includes(technique?.techniqueParentId);
    });

    setForm((prevForm) => ({
      ...prevForm,
      tacticIds,
      techniqueIds,
      subTechniqueIds,
    }));
  };

  const handleChangeTechnique = (techniqueIds) => {
    const subTechIds = form.subTechniqueIds || [];
    const tactIds = form.tacticIds || [];

    const subTechniqueIds = subTechIds.filter((id) => {
      const technique = subTechniquesMap.get(id);
      return (
        tactIds.includes(technique.tacticId) ||
        techniqueIds.includes(technique?.techniqueParentId)
      );
    });

    setForm((prevForm) => ({
      ...prevForm,
      techniqueIds,
      subTechniqueIds,
    }));
  };

  const handleChangeCategory = (category) => {
    let selectedCategories =
      categories.filter((x) => category.includes(x.id)) || [];

    const categoryIds = _.map(selectedCategories, "id");

    let categoryTypeIds = form.categoryTypeIds || [];

    if (selectedCategories.length > 0) {
      categoryTypeIds = _.map(
        _.flatten(_.map(selectedCategories, "types")).filter((x) =>
          categoryTypeIds.includes(x.id)
        ),
        "id"
      );
    } else {
      categoryTypeIds = [];
    }

    setForm((prevForm) => ({
      ...prevForm,
      categoryIds,
      categoryTypeIds,
    }));
  };

  const attackTypeOptions = useMemo(() => {
    if (form.categoryIds && form.categoryIds.length > 0) {
      const selectedCategories = categories.filter((x) =>
        form.categoryIds.includes(x.id)
      );

      return createOptions(
        _.flatten(_.map(selectedCategories, "types")),
        "name"
      );
    } else {
      return [];
    }
  }, [form.categoryIds]);

  useEffect(() => {
    if (setAttackFormCharConstraint) {
      if (form?.name.length > 128 || form?.description.length > 2048)
        setAttackFormCharConstraint(false);
      else setAttackFormCharConstraint(true);
    }
  }, [form?.name, form?.description]);

  const tacticIds = form.tacticIds || [];
  const techniqueIds = form.techniqueIds || [];
  const subTechniqueIds = form.subTechniqueIds || [];

  const recommendations = useMemo(() => {
    return form?.recommendation ? form?.recommendation.split("<br/>") : [""];
  }, [form?.recommendation]);

  const handleRemoveRecommendation = (index) => {
    const recommendation = cloneDeep(recommendations);
    recommendation.splice(index, 1);
    if (recommendation.length === 0) {
      recommendation.push("");
    }
    setForm((prevForm) => ({
      ...prevForm,
      recommendation: recommendation.join("<br/>"),
    }));
  };

  const handleUpdateRecommendation = (value, index) => {
    const recommendation = cloneDeep(recommendations);
    recommendation[index] = value;

    setForm((prevForm) => ({
      ...prevForm,
      recommendation: recommendation.join("<br/>"),
    }));
  };

  const handleAddRecommendation = () => {
    const recommendation = cloneDeep(recommendations);
    recommendation.push("");
    setForm((prevForm) => ({
      ...prevForm,
      recommendation: recommendation.join("<br/>"),
    }));
  };

  const [openPreview, setOpenPreview] = useState(false);
  const [preview, setPreview] = useState({});

  const handlePreview = (fileUrl, format, encodedAttackPathFile = null) => {
    setPreview({ fileUrl, format, encodedAttackPathFile });
    setOpenPreview(true);
  };

  const handleDeleteFile = async (filePath, type) => {
    const url = `${API_URL}/api/attacks/${form.id}/${type}`;
    try {
      await deleteData(url);
      getAttack(form.id);
    } catch (e) {}
  };

  const handleDownloadFile = async (name, filePath, extension) => {
    const url = `${API_URL}${filePath}`;
    const fileName = name?.split(".")[0];
    try {
      await downloadFile(url, fileName, extension);
    } catch (e) {}
  };

  const GridWrapper = useCallback(({ children }) => {
    return (
      <Grid
        item
        style={{ width: isFullWidth ? "100%" : undefined }}
        md={isFullWidth ? undefined : 6}
        lg={isFullWidth ? undefined : 6}
        xs={isFullWidth ? undefined : 12}
      >
        {children}
      </Grid>
    );
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container direction={"row"} spacing={2}>
            <GridWrapper>
              <TextField
                variant="standard"
                margin="none"
                placeholder={"Enter name"}
                disabled={busy}
                fullWidth
                required={true}
                value={form.name}
                onChange={handleChange("name")}
                label="Name"
                helperText={
                  form?.name.length > 128 && (
                    <p style={{ color: "#EF4444" }}>
                      Attack name should not exceed 128 characters
                    </p>
                  )
                }
              />
            </GridWrapper>

            <GridWrapper>
              <TextField
                variant="standard"
                margin="none"
                placeholder={"Enter description"}
                fullWidth
                required={true}
                value={form.description}
                multiline
                rowsMax={10}
                disabled={busy}
                onChange={handleChange("description")}
                label="Description"
                helperText={
                  form?.description.length > 2048 && (
                    <p style={{ color: "#EF4444" }}>
                      Attack description should not exceed 2048 characters
                    </p>
                  )
                }
              />
            </GridWrapper>

            <GridWrapper>
              <SearchSelect
                placeholder={"Select"}
                label={"Version"}
                controlId={"version"}
                onChange={handleChange("version")}
                required={true}
                value={form.version}
                disabled={busy || form.id}
                margin={"none"}
                options={VERSIONS}
              />
            </GridWrapper>
            <GridWrapper>
              <TextField
                variant="standard"
                margin="none"
                placeholder={"Enter Shortname"}
                disabled={busy}
                fullWidth
                required={true}
                value={form.shortName}
                onChange={handleChange("shortName")}
                label="Short Name"
                helperText={
                  form?.shortName?.length > 30 && (
                    <p style={{ color: "#EF4444" }}>
                      Short name should not exceed 30 characters
                    </p>
                  )
                }
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                required={true}
                placeholder={"Select"}
                label={"Category"}
                controlId={"categoryIds"}
                disabled={busy}
                onChange={handleChangeCategory}
                values={form.categoryIds}
                margin={"none"}
                options={categoryOptions}
                labelId={"Category"}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                required={true}
                placeholder={"Select"}
                label={"Type"}
                controlId={"categoryTypeIds"}
                disabled={busy}
                onChange={(type) => {
                  setForm((prevForm) => ({
                    ...prevForm,
                    categoryTypeIds: type,
                  }));
                }}
                values={form.categoryTypeIds}
                margin={"none"}
                options={attackTypeOptions}
                labelId={"categoryTypeIds"}
              />
            </GridWrapper>
            <GridWrapper>
              <Select
                required={true}
                placeholder={"Select"}
                label={"Attack Phase "}
                controlId={"attackPhase"}
                disabled={busy}
                onChange={handleChange("attackPhase")}
                // value={form.attackPhase}
                value={`${form.attackPhase}`}
                margin={"none"}
                options={ATTACK_PHASE_OPTIONS}
                labelId={"attackPhase"}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                required={true}
                placeholder={"Select"}
                label={"Prevention Controls"}
                controlId={"preventionControls"}
                disabled={busy}
                onChange={(preventionControls) => {
                  setForm((prevForm) => ({
                    ...prevForm,
                    preventionControls,
                  }));
                }}
                values={form.preventionControls}
                margin={"none"}
                options={ATTACK_PREVENTION_CONTROLS_OPTIONS}
                labelId={"preventionControls"}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                required={
                  form.type === AttackTypes.MITRE_ATTACK ||
                  form.type === AttackTypes.ADVERSARY
                }
                placeholder={"Select"}
                label={"Tactic"}
                controlId={"tacticIds"}
                disabled={busy}
                onChange={handleChangeTactic}
                values={tacticIds}
                margin={"none"}
                options={tacticOptions}
                labelId={"tacticIds"}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                required={
                  form.type === AttackTypes.MITRE_ATTACK ||
                  form.type === AttackTypes.ADVERSARY
                }
                placeholder={"Select"}
                label={"Technique"}
                controlId={"techniqueIds"}
                disabled={busy}
                onChange={handleChangeTechnique}
                values={techniqueIds}
                margin={"none"}
                options={techniqueOptions}
                labelId={"techniqueIds"}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                required={
                  form.type === AttackTypes.MITRE_ATTACK ||
                  form.type === AttackTypes.ADVERSARY
                }
                placeholder={"Select"}
                label={"Sub-technique"}
                controlId={"subTechniqueIds"}
                disabled={busy}
                onChange={handleChange("subTechniqueIds")}
                values={subTechniqueIds}
                margin={"none"}
                options={subTechniqueOptions}
                labelId={"subTechniqueIds"}
              />
            </GridWrapper>
            <GridWrapper>
              <ButtonChoseList
                disabled={busy}
                onClick={openActors}
                title={"Actors"}
                required={form.type === AttackTypes.ADVERSARY}
                selected={form.actorIds}
              />
            </GridWrapper>
            <GridWrapper>
              <ButtonChoseList
                disabled={busy}
                onClick={openCves}
                title={"CVES"}
                required={form.type === AttackTypes.VULNERABILITY}
                selected={form.cves}
              />
            </GridWrapper>
            <GridWrapper>
              <FreeSolo
                value={form.adversaryTools}
                onChange={handleChangeAdversaryTools}
                label={"Adversary Tools"}
                required={form.type === AttackTypes.ADVERSARY}
                placeholder={"Enter adversary tool"}
              />
            </GridWrapper>
            <GridWrapper>
              <ButtonChoseList
                selected={form.signatures || []}
                title={"Signatures"}
                required
                onClick={() => setOpenSignatures(true)}
              />
            </GridWrapper>
            <GridWrapper>
              <SearchSelect
                placeholder={"Select"}
                label={"Format"}
                controlId={"format"}
                margin={"none"}
                onChange={handleChange("format")}
                value={form.format}
                disabled={busy}
                required
                options={ATTACK_FORMAT_TYPES}
              />
            </GridWrapper>
            <GridWrapper>
              <SearchSelect
                placeholder={"Select"}
                label={"Source Type"}
                controlId={"sourceType"}
                margin={"none"}
                onChange={handleChange("sourceType")}
                value={form.sourceType}
                disabled={busy}
                required
                options={ATTACK_SOURCE_TYPES}
              />
            </GridWrapper>
            <GridWrapper>
              <Select
                onChange={handleChange("target")}
                value={`${form.target}`}
                controlId={"target"}
                label={"Target"}
                margin={"none"}
                required
                options={TARGET_TYPES}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                fullWidth
                options={ATTACK_SUPPORTED_SYSTEMS}
                label={"Supported Platforms"}
                onChange={handleChange("supportedPlatforms")}
                values={form.supportedPlatforms || []}
                labelId={"supportedPlatforms"}
                disabled={busy}
                required
                margin={"none"}
              />
            </GridWrapper>
            <GridWrapper>
              <MultiSelect
                fullWidth
                margin={"none"}
                options={ATTACK_EXECUTORS}
                label={"Executors"}
                disabled={busy}
                onChange={handleChange("executors")}
                required
                values={form.executors || []}
                labelId={"executors"}
              />
            </GridWrapper>
            <GridWrapper>
              <FreeSolo
                value={form.affectedPrograms || []}
                onChange={handleChangeAffectedProducts}
                label={"Affected Programs"}
                options={AFFECTED_PRODUCTS.map((x) => x.value)}
                required
                placeholder={"Enter Affected Programs"}
                labelId={"affectedProducts"}
              />
            </GridWrapper>
            <GridWrapper>
              <FreeSolo
                value={form.references || []}
                onChange={handleReferences}
                label={"References"}
                required
                placeholder={"Enter References"}
                labelId={"references"}
              />
            </GridWrapper>
            <GridWrapper>
              <Box display={"flex-inline"} marginTop={2}>
                <Grid container spacing={"1"} direction="row">
                  <Grid item md={4} lg={4}>
                    <Typography
                      style={{
                        paddingTop: "10x",
                      }}
                      variant="body2"
                    >
                      Authentication
                    </Typography>
                  </Grid>
                  <RadioGroup
                    row
                    aria-label="authentication"
                    name="authentication"
                    value={form.authentication}
                    onChange={(e) => {
                      setForm((prevForm) => {
                        return {
                          ...prevForm,
                          authentication: e.target.value === "true",
                          credentialType:
                            e.target.value === "false"
                              ? null
                              : prevForm.credentialType,
                        };
                      });
                    }}
                  >
                    <Grid item md={6} lg={6}>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                    </Grid>
                    <Grid item md={2} lg={2}>
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Box>
            </GridWrapper>
            <GridWrapper>
              <Select
                onChange={(cType) => {
                  setForm((prevForm) => {
                    return {
                      ...prevForm,
                      credentialType:
                        prevForm.authentication === false
                          ? null
                          : cType.target.value,
                    };
                  });
                }}
                value={form.credentialType}
                controlId={"credentialType"}
                label={"Credential Type"}
                required
                margin={"none"}
                options={CREDENTIAL_TYPES}
                disabled={form.authentication === false}
              />
            </GridWrapper>
            <GridWrapper>
              <Paper variant={"outlined"} style={{ padding: 16 }}>
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <SectionGroupDescription
                      title={""}
                      IconComponent={FolderOpen}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={loading || form?.hasPdfFile || busy}
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() =>
                        setOpenFileUpload({
                          from: ATTACK_SIGMA_RULES,
                          status: true,
                        })
                      }
                    >
                      <HiveIcons.AddOutlined />
                      Add Sigma Rules
                    </Button>
                  </Grid>
                </Grid>

                {selectedSigmaRuleFile.length > 0 && (
                  <Grid container>
                    <Grid item style={{ width: "100%" }}>
                      <FileCard
                        file={selectedSigmaRuleFile[0].path}
                        busy={busy}
                        handleDelete={() => {
                          setSelectedSigmaRuleFile([]);
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {form && form.sigmaRulesFile && (
                  <Grid container>
                    <Grid item style={{ width: "100%" }}>
                      <FileCard
                        file={form?.sigmaRulesFile?.name}
                        busy={busy}
                        handlePreview={() =>
                          handlePreview(
                            form?.sigmaRulesFile.path,
                            form?.sigmaRulesFile.format
                          )
                        }
                        handleDownload={() =>
                          handleDownloadFile(
                            form?.sigmaRulesFile.name,
                            form?.sigmaRulesFile.path,
                            form?.sigmaRulesFile?.format
                          )
                        }
                        handleDelete={() => {
                          handleDeleteFile(form?.sigmaRulesFile, "sigma-rules");
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </GridWrapper>
            <GridWrapper>
              <Paper variant={"outlined"} style={{ padding: 16 }}>
                <Grid container>
                  <Grid item style={{ flex: 1 }}>
                    <SectionGroupDescription
                      title={""}
                      IconComponent={FolderOpen}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={loading || busy}
                      color={"primary"}
                      variant={"outlined"}
                      onClick={() =>
                        setOpenFileUpload({ from: ATTACK_PATH, status: true })
                      }
                    >
                      <HiveIcons.AddOutlined />
                      Add Attack Path
                    </Button>
                  </Grid>
                </Grid>
                {selectedAttackPathFile.length > 0 && (
                  <Grid container>
                    <Grid item style={{ width: "100%" }}>
                      <FileCard
                        file={selectedAttackPathFile[0].path}
                        busy={busy}
                        handleDelete={() => {
                          setSelectedAttackPathFile([]);
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {form && form.attackPathFile && (
                  <Grid container>
                    <Grid item style={{ width: "100%" }}>
                      <FileCard
                        file={form?.attackPathFile?.filename}
                        busy={busy}
                        handlePreview={() =>
                          handlePreview(
                            null,
                            form?.attackPathFile.format,
                            form?.attackPathFile.encodedAttackPathFile
                          )
                        }
                        handleDownload={() => {
                          // handleDownloadFile(
                          //   form?.attackPathFile.filename,
                          //   form?.attackPathFile.path,
                          //   form?.attackPathFile?.format
                          // );
                        }}
                        handleDelete={() => {
                          handleDeleteFile(form?.attackPathFile, "attack-path");
                        }}
                        canDownload={false}
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </GridWrapper>

            <BaseDialog
              title={
                isOpenFileUpload.from == ATTACK_SIGMA_RULES
                  ? "Upload Sigma Rules"
                  : "Upload Attack Path"
              }
              showProgress
              inProgress={isUploading}
              primaryActionTitle={"Attach"}
              primaryAction={handleUpload}
              onClose={onCloseUploadDialog}
              open={isOpenFileUpload.status}
              errorMessage={uploadError}
              isValid={
                isOpenFileUpload.from == ATTACK_SIGMA_RULES
                  ? selectedSigmaRuleFile[0]
                  : selectedAttackPathFile[0]
              }
              dismissError={() => setUploadError(null)}
            >
              <Grid container direction={"column"} spacing={2}>
                <Grid item>
                  <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                      <Typography
                        color={"textSecondary"}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {isOpenFileUpload.from == ATTACK_SIGMA_RULES
                          ? "Upload Sigma Rules"
                          : "Upload Attack Path"}
                      </Typography>
                    </Grid>
                    {isOpenFileUpload.from !== ATTACK_SIGMA_RULES && (
                      <>
                        <Grid item>
                          <Divider
                            orientation={"vertical"}
                            style={{ height: 14 }}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            color={"primary"}
                            size={"small"}
                            onClick={() => {
                              setOpenFileUpload({
                                from: ATTACK_PATH,
                                status: false,
                              });
                            }}
                          ></Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <div style={{ height: 250 }}>
                    {isOpenFileUpload.from == ATTACK_SIGMA_RULES ? (
                      <HiDrop
                        selectedFiles={selectedSigmaRuleFile}
                        handleAcceptedFiles={handleAcceptSigmaRules}
                        title={"Drag and drop here"}
                        setSelectedFiles={setSelectedSigmaRuleFile}
                        link={"Upload file"}
                        accept={".yaml, .yml"}
                        isMultiple={false}
                      />
                    ) : (
                      <HiDrop
                        selectedFiles={selectedAttackPathFile}
                        handleAcceptedFiles={handleAcceptAttackPathFile}
                        title={"Drag and drop here"}
                        setSelectedFiles={setSelectedAttackPathFile}
                        link={"Upload file"}
                        accept={".png, .gif"}
                        isMultiple={false}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </BaseDialog>

            <Grid item style={{ width: "100%" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  color={"textPrimary"}
                  style={{ fontWeight: "bold" }}
                >
                  Recommendation *
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleAddRecommendation}
                    style={{ marginLeft: 16 }}
                    size={"small"}
                  >
                    <AddCircleOutline
                      style={{ marginRight: 4 }}
                      color={"primary"}
                    />
                    Add Recommendation
                  </Button>
                </Box>
              </Box>

              {recommendations.map((rec, index) => {
                return (
                  <>
                    <Grid item container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <TextField
                          id="name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                          placeholder={"Enter Recommendation"}
                          value={rec}
                          disabled={busy}
                          onChange={(e) => {
                            handleUpdateRecommendation(e.target.value, index);
                          }}
                          label={`Recommendation #${index + 1}`}
                        />
                        <IconButton
                          onClick={() => {
                            handleRemoveRecommendation(index);
                          }}
                        >
                          <RemoveCircleOutline color={"error"} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
            <SignatureDialog
              open={openSignatures}
              handleClose={() => setOpenSignatures(false)}
              form={form}
              setForm={setForm}
            />
          </Grid>
        </>
      )}
      <FilePreview
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
        preview={preview}
      />
    </>
  );
}

export default AttackForm;
