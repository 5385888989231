import React, { useEffect, useState } from "react";
import BaseDialog from "./Common/BaseDialog";
import PropTypes from "prop-types";
import { useEntityProgress } from "reactcoregk/hooks";
import ConfirmationDialog from "./Common/ConfirmationDialog";

const EntityDialog = (props) => {
  const {
    handleClose,
    open,
    createEntity,
    updateEntity,
    context,
    form,
    entityType,
    // showDeleteModal,
    // setShowDeleteModal,
    extraClassName,
    isValid,
    primaryActionTitle,
    handleEditTitle,
    keepOpen,
    titleOverride,
    deleteEntity,
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCreate = () => {
    return form.id ? updateEntity(form) : createEntity(form);
  };

  const handleDelete = () => {
    deleteEntity(form);
    setOpenDelete(false);
  };

  const onDelete = () => {
    setOpenDelete(true);
  };

  const onClose = keepOpen ? function () {} : handleClose;
  const [busy, error] = useEntityProgress(context, onClose);

  useEffect(() => setErrorMessage(error), [error]);
  useEffect(() => {
    if (!open) {
      setErrorMessage(null);
    }
  }, [open]);

  return (
    <>
      <BaseDialog
        title={
          titleOverride || `${form.id ? "Edit" : "Create new"} ${entityType}`
        }
        handleEditTitle={handleEditTitle}
        primaryAction={handleCreate}
        primaryActionTitle={primaryActionTitle}
        inProgress={busy}
        open={open}
        size={props.size}
        onClose={handleClose}
        scrollable={true}
        extraClassName={extraClassName}
        isValid={isValid}
        errorMessage={errorMessage}
        showProgress
        dismissError={() => setErrorMessage(null)}
        onDelete={form.id && deleteEntity ? onDelete : null}
        {...props}
      >
        {props.children}
      </BaseDialog>
      <ConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        title={`Delete ${entityType}`}
        description={`Are you sure you want to delete this ${entityType}?`}
        primaryActionTitle={"Delete"}
        secondaryActionTitle={"Cancel"}
        primaryAction={handleDelete}
        secondaryAction={() => setOpenDelete(false)}
      />
    </>
  );
};

EntityDialog.defaultProps = {
  fullWidth: true,
};

EntityDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  titleOverride: PropTypes.string,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  fullWidth: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  createEntity: PropTypes.func,
  updateEntity: PropTypes.func,
  context: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isValid: PropTypes.bool,
};

export default EntityDialog;
