import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
} from "@material-ui/core";
import ArsenalCard from "./ArsenalCard";
import { useCurrentPageParam, usePageableEntity } from "../../helpers/hooks";
import SubmenuLayout from "../../components/Layouts/SubmenuLayout";
import NewCollectionDialog from "./NewCollectionDialog";
import Subheader from "./subheader";
import { connect } from "react-redux";
import {
  createCollection,
  getAllCollections,
} from "../../store/collection/actions";
import { Pagination } from "@material-ui/lab";
import { getParameterByName } from "../../helpers/utils";

function Collections(props) {
  const { createCollection, context, getAllCollections } = props;
  const [selected, setSelected] = useState(null);
  const [openNew, setOpenNew] = useState(false);
  const [step, setStep] = useState(2);
  const [collection, setCollection] = useState({});
  const rootParams = props.location.search.replace("?", "&");
  const [currentPage, setCurrentPage] = useCurrentPageParam();
  const [selectedCollections, setSelectedCollections] = useState([]);

  // Callback to trigger on useDependency hook
  // Get state for current entity, and handler to manage paging
  const { list, totalPages, isLoading } = usePageableEntity(
    context.Collection,
    getAllCollections,
    rootParams
  );
  const handleCopy = () => {
    setOpenNew(true);
    setStep(2);
  };

  const handleEntityClick = (collection) => {
    props.history.push(`/collections/${collection.uuid}`);
  };

  const AttackMenu = (
    <Subheader
      handleCreateNew={() => setOpenNew(true)}
      selected={selectedCollections}
      {...props}
    />
  );

  const addToExistingCollection =
    getParameterByName("addToExistingCollection") === "true";
  const copyFromCollection =
    getParameterByName("copyFromCollection") === "true";

  // const isSelectable = addToExistingCollection || copyFromCollection;
  const isSelectable = true;

  const handleClick = (arsenal) => {
    if (selected === arsenal.uuid) {
      setSelected(null);
      setCollection({});
    } else {
      setCollection(arsenal);
      setSelected(arsenal.uuid);
    }
    setSelectedCollections((selectedCollections) => {
      if (selectedCollections.find((col) => col.uuid === arsenal.uuid)) {
        return selectedCollections.filter((col) => col.uuid !== arsenal.uuid);
      }
      return [...selectedCollections, arsenal];
    });
  };

  const renderFooter = () => (
    <Box>
      {addToExistingCollection && (
        <Button
          onClick={() => handleEntityClick({ uuid: selected })}
          disabled={!selected}
          fullWidth
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      )}
      {copyFromCollection && (
        <Button
          disabled={!selected}
          onClick={handleCopy}
          fullWidth
          variant="contained"
          color="primary"
        >
          Select and Copy
        </Button>
      )}
    </Box>
  );

  return (
    <SubmenuLayout title={"Collections"} MenuItemsComponent={AttackMenu}>
      <Box style={{ width: "100%", height: "100%" }}>
        <Container maxWidth={false} style={{ height: "calc(100vh - 120px)" }}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div style={{ flex: 1, overflow: "auto", overflowX: "hidden" }}>
              <Grid container spacing={2}>
                {isLoading
                  ? [...Array(10)].map((a, i) => (
                      <Grid item xs={6} md={4} lg={3} xl={2}>
                        <ArsenalCard isLoading={true} arsenal={{}} />
                      </Grid>
                    ))
                  : list.map((collection, i) => (
                      <Grid item xs={6} md={4} lg={3} xl={2}>
                        <ArsenalCard
                          handleViewDetails={handleEntityClick}
                          isLoading={false}
                          handleSelect={handleClick}
                          isSelectable={isSelectable}
                          // isSelected={collection.uuid === selected}
                          isSelected={selectedCollections.find(
                            (col) => col.uuid === collection.uuid
                          )}
                          arsenal={{
                            ...collection,
                            createdBy: collection.createdByUsername,
                            type: collection.collectionType,
                            arsenalType: "collection",
                          }}
                        />
                      </Grid>
                    ))}
              </Grid>
              <Box mt={2} component={Grid} container justify={"center"}>
                <Pagination
                  onChange={(e, newPage) => setCurrentPage(newPage - 1)}
                  page={currentPage}
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  size={"small"}
                />
              </Box>
            </div>
            <Collapse in={isSelectable}>
              <div>
                <Divider />
              </div>
              <Box p={2} display={"flex"} justifyContent={"flex-end"}>
                {renderFooter()}
              </Box>
            </Collapse>
          </div>
        </Container>
      </Box>
      <NewCollectionDialog
        open={openNew}
        step={step}
        setStep={setStep}
        collection={collection}
        history={props.history}
        context={context.Collection}
        createCollection={createCollection}
        handleClose={() => setOpenNew(false)}
      />
    </SubmenuLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};
export default connect(mapStateToProps, {
  getAllCollections,
  createCollection,
})(Collections);
