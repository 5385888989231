export const handleCollectionValidations = (form, setError, callback) => {
  if (!form.name) {
    return false;
  } else if (!form.description) {
    return false;
  } else if (!form.version) {
    return false;
  } else if (!form.shortName || form.shortName === "" || form.shortName > 30) {
    return false;
  } else if (!form.categoryIds || form.categoryIds?.length === 0) {
    return false;
  } else if (!form.categoryTypeIds || form.categoryTypeIds.length === 0) {
    return false;
  } else if (form.version === 1 && form.categoryIds.length > 0) {
    setError("Version 1 cannot have Category & Types");
    return false;
  }
};
