import { motion } from "framer-motion";
import { slideVariants } from "../../constants/constants";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseDialog from "../../components/Common/BaseDialog";
import Grid from "@material-ui/core/Grid";
import Title from "../../components/Common/Title";
import FormControl from "@material-ui/core/FormControl";
import {
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useEntityProgress, useForm } from "reactcoregk/hooks";
import TextField from "@material-ui/core/TextField";
import Select from "../../components/Inputs/Select";
import { COLLECTION_TYPES, VERSIONS } from "../../constants/enums";
import SearchSelect from "components/Inputs/SearchSelect";
import MultiSelect from "components/Inputs/MultiSelect";
import { fetchData } from "helpers/utils";
import { API_URL } from "config";
import _ from "lodash";
import { createOptions } from "reactcoregk/utils";
import Loader from "components/Common/Loader";
import { handleCollectionValidations } from "./logic";
import { Alert } from "@material-ui/lab";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ flex: 1 }}
      {...other}
      className="wrapper"
    >
      {value === index && (
        <motion.div
          variants={slideVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {children}
        </motion.div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function NewCollectionDialog(props) {
  const {
    open,
    handleClose,
    history,
    context,
    createCollection,
    collection,
    step,
    setStep,
  } = props;
  const [form, handleChange, setForm] = useForm({
    // shouldCreate: "true",
    // shouldCopy: "false",
    name: "",
    description: "",
    // visible: "false",
    attackIds: [],
    categoryIds: [],
    categoryTypeIds: [],
    version: 1,
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  useEffect(() => {
    if (open) {
      setStep(2);
      setLoading(true);
      fetchData(API_URL + "/api/bas/categories")
        .then((res) => {
          setCategories(
            _.filter(res, (x) => x.entityType === "BAS_ATTACK_COLLECTION")
          );
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, setStep]);

  const categoryOptions = createOptions(categories, "name");

  const handleNext = () => {
    // if (step === 0) {
    //   if (form.shouldCreate === "false") {
    //     handleClose();
    //     history.push("/collections?addToExistingCollection=true");
    //   } else {
    //     setStep(step + 1);
    //   }
    // } else if (step === 1) {
    //   if (form.shouldCopy === "true") {
    //     history.push("/collections?copyFromCollection=true");
    //     handleClose();
    //   } else {
    //     setStep(step + 1);
    //   }
    // } else {
    // let attackIds = [];
    // if (collection?.attackIds?.length > 0 && form.shouldCopy === "true") {
    //   attackIds = collection.attackIds;
    // }
    // }

    createCollection({ ...form });
  };

  const handleRedirect = useCallback(() => {
    const id = context.create.result?.uuid;
    history.push(`/collections/${id}`);
  }, [context.create.result, history]);

  const [busy, error] = useEntityProgress(context, handleRedirect);

  const handleChangeCategory = (category) => {
    let selectedCategories =
      categories.filter((x) => category.includes(x.id)) || [];

    const categoryIds = _.map(selectedCategories, "id");

    let categoryTypeIds = form.categoryTypeIds || [];

    if (selectedCategories.length > 0) {
      categoryTypeIds = _.map(
        _.flatten(_.map(selectedCategories, "types")).filter((x) =>
          categoryTypeIds.includes(x.id)
        ),
        "id"
      );
    } else {
      categoryTypeIds = [];
    }

    setForm((prevForm) => ({
      ...prevForm,
      categoryIds,
      categoryTypeIds,
    }));
  };

  const attackTypeOptions = useMemo(() => {
    if (form.categoryIds && form.categoryIds.length > 0) {
      const selectedCategories = categories.filter((x) =>
        form.categoryIds.includes(x.id)
      );

      return createOptions(
        _.flatten(_.map(selectedCategories, "types")),
        "name"
      );
    } else {
      return [];
    }
  }, [form.categoryIds]);

  const isValid = useMemo(() => {
    setErrorMsg("");
    return handleCollectionValidations(form, setErrorMsg);
  }, [form]);

  return (
    <BaseDialog
      open={open}
      primaryAction={handleNext}
      title={"Create Selection"}
      inProgress={busy}
      errorMessage={error}
      primaryActionTitle={step === 2 ? "Save" : "Continue"}
      onClose={handleClose}
      showProgress
      isValid={isValid}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Collapse in={errorMsg}>
            <Alert
              severity="error"
              onClose={() => setErrorMsg("")}
              style={{ marginBottom: 8 }}
            >
              {errorMsg}
            </Alert>
          </Collapse>
          <TabPanel index={step} value={0}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <Title>
                  Create new Collection or add to existing collection?
                </Title>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="shouldCreate"
                    name="shouldCreate"
                    value={form.shouldCreate}
                    onChange={handleChange("shouldCreate")}
                  >
                    <FormControlLabel
                      value={"true"}
                      control={<Radio />}
                      label="Create new Collection"
                    />
                    <FormControlLabel
                      value={"false"}
                      control={<Radio />}
                      label="Add to existing Collection"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel index={step} value={1}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <Title>Do you want to copy an existing collection?</Title>
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="shouldCopy"
                    name="shouldCopy"
                    value={form.shouldCopy}
                    onChange={handleChange("shouldCopy")}
                  >
                    <FormControlLabel
                      value={"true"}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={"false"}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel index={step} value={2}>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <Title>Enter details</Title>
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    variant="standard"
                    margin="none"
                    placeholder={"Enter name"}
                    disabled={busy}
                    fullWidth
                    value={form.name}
                    onChange={handleChange("name")}
                    label="Name"
                    autoFocus
                    helperText={
                      form?.name.length > 128 && (
                        <p style={{ color: "#EF4444" }}>
                          Collection name should not exceed 128 characters.
                        </p>
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    variant="standard"
                    margin="none"
                    placeholder={"Enter Description"}
                    disabled={busy}
                    fullWidth
                    multiline
                    rowsMax={10}
                    value={form.description}
                    onChange={handleChange("description")}
                    label="Description"
                    helperText={
                      form?.description.length > 2048 && (
                        <p style={{ color: "#EF4444" }}>
                          Collection description should not exceed 2048
                          characters.
                        </p>
                      )
                    }
                  />
                </Grid>
              </Grid>
              {/* <Grid item>
            <Grid item>
              <Select
                variant="standard"
                margin="none"
                placeholder={"Enter Type"}
                disabled={busy}
                fullWidth
                value={form.collectionType}
                onChange={handleChange("collectionType")}
                options={COLLECTION_TYPES}
                label="Type"
              />
            </Grid>
          </Grid> */}

              <Grid item>
                <SearchSelect
                  placeholder={"Select"}
                  label={"Version"}
                  controlId={"version"}
                  onChange={handleChange("version")}
                  required={true}
                  value={form.version}
                  disabled={busy || form.id}
                  margin={"none"}
                  options={VERSIONS}
                />
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  margin="none"
                  placeholder={"Enter Shortname"}
                  disabled={busy}
                  fullWidth
                  required={true}
                  value={form.shortName}
                  onChange={handleChange("shortName")}
                  label="Short Name"
                  helperText={
                    form?.shortName?.length > 30 && (
                      <p style={{ color: "#EF4444" }}>
                        Short name should not exceed 30 characters
                      </p>
                    )
                  }
                />
              </Grid>
              <Grid item>
                <MultiSelect
                  required={true}
                  placeholder={"Select"}
                  label={"Category"}
                  controlId={"categoryIds"}
                  disabled={busy}
                  onChange={handleChangeCategory}
                  values={form.categoryIds}
                  margin={"none"}
                  options={categoryOptions}
                  labelId={"Category"}
                />
              </Grid>
              <Grid item>
                <MultiSelect
                  required={true}
                  placeholder={"Select"}
                  label={"Type"}
                  controlId={"categoryTypeIds"}
                  disabled={busy}
                  onChange={(type) => {
                    setForm((prevForm) => ({
                      ...prevForm,
                      categoryTypeIds: type,
                    }));
                  }}
                  values={form.categoryTypeIds}
                  margin={"none"}
                  options={attackTypeOptions}
                  labelId={"categoryTypeIds"}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </>
      )}
    </BaseDialog>
  );
}

export default NewCollectionDialog;
